export const FirebaseConfig = {
	"projectId": "rosy-crawler-407800",
	"appId": "1:248961899708:web:d69ad78ebc644c4ea804b9",
	"databaseURL": "https://rosy-crawler-407800-default-rtdb.firebaseio.com",
	"storageBucket": "rosy-crawler-407800.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyDOMgGCdIU_9gGYfVZn9EIrDe7L3bqjoTc",
	"authDomain": "rosy-crawler-407800.firebaseapp.com",
	"messagingSenderId": "248961899708",
	"measurementId": "G-QSE7F58XMB"
};